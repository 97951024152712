<template>
  <div id="graphics-shapes" class="graphics-wrap color-overlay-icons">
    <div
      class="item"
      v-for="(componentName, index) in blobComponents"
      @click="addELToCanvas(index)"
      :ref="'svg' + index"
      :key="index"
    >
      <component :class="'svg' + index" :is="`${componentName}`"></component>
    </div>
  </div>
</template>

<script>
import { GRAPHICS_MIXIN } from "../graphics.mixins";

export default {
  mixins: [GRAPHICS_MIXIN],
  computed: {
    blobComponents: function() {
      return Array.from(Array(24).keys());
    },
  },
  created() {
    // https://stackoverflow.com/a/58081641/1247864
    for (let c = 0; c < this.blobComponents.length; c++) {
      let componentName = this.blobComponents[c];

      this.$options.components[`${componentName}`] = () =>
        import("../assets/blobs/" + componentName + ".vue");
    }
  },
  methods: {
    addELToCanvas(index) {
      this.$_graphicsMixin_addSVGToCanvas(index);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../index.component.scss";
.graphics-wrap {
  .item {
    padding: 0 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70px;
  }
}
</style>
